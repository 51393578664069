import './css/App.css';
import { useState } from 'react';
import cryingCat1 from './images/cryingCat1.png'
import cryingCat2 from './images/cryingCat2.png'
import cryingCat3 from './images/cryingCat3.png'
import cryingCat4 from './images/cryingCat4.png'
import cryingCat5 from './images/cryingCat5.png'
import happyCat from './images/happy-cat-cat.gif'
import bananaCryingCat from './images/banana-crying-cat.gif'
const phrases = [
  "No",
  "Please?",
  "Pretty Please?",
  "Yo Quit Playin :'(",
  "You better not click this again >:(",
  "PLEASE CLICK THE YES BUTTON IM DESPERATE!!!",
]

const images = [
  cryingCat1, bananaCryingCat, cryingCat2, cryingCat3, cryingCat4, cryingCat5
]
function App() {
const [noCount, setNoCount] = useState(0);
const [yesPressed, setYesPressed] = useState(false);
const yesButtonSize = noCount * 20 + 16;
const curPic = images[Math.min(noCount, phrases.length - 1)];



function handleNoClick() {
  setNoCount(noCount + 1);
}

function getNoButtonText() {
  return phrases[Math.min(noCount, phrases.length - 1)];
}

  return (
    <div className='valentine-container'>
      {yesPressed ? (
        <>
          <img 
            src={ happyCat }
            alt="happy cat" 
          />
          <audio>
            <source src='https://www.youtube.com/watch?v=ps74zeevi-g&ab_channel=SoundEffectCenter' type="audio/oog"/>
         </audio>
          <div className='text'>WOOOO HOOO!!!!!</div>
        </>
      ) : (
        <>
          <img
            alt='Sad Cat Pic'
            src= { curPic }
          />

          <div>Will You Be My Valentine Zi?</div>
          <div>
          <audio>
            <source src='https://www.youtube.com/watch?v=RHWy1EsXnAo&ab_channel=MehdiLaidouniSuperMario' type="audio/mpeg"/>
         </audio>
            <button
            className="yesButton"
            style={{ fontSize: yesButtonSize }}
            onClick={() => setYesPressed(true)}
            >
              Yes
            </button>
            <button onClick={handleNoClick} className='noButton'>
              {getNoButtonText()}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
